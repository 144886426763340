import axios from 'axios';
import { notification, Statistic } from 'antd';
import { sessionClear } from './functionTool';
import { GetDecryptedString } from './encryption';
const { Countdown } = Statistic;

// 創建新的axios實例
const customAxios = axios.create();

const logout = () => {
  const currentPath = window.location.pathname;
  if (!currentPath.includes('/login') && !currentPath.includes('/auth')) {
    sessionStorage.setItem('redirectPath', currentPath);
  }
  notification.warning({
    message: (
      <div>
        登入逾時，系統將於
        <Countdown
          value={Date.now() + 1000}
          format="s"
          onFinish={() => (window.location.href = '/login')}
          style={{ display: 'inline-block', padding: '0 2px' }}
          className="logout-count-down"
        />
        秒後自動登出，請再次登入
      </div>
    ),
    placement: 'top',
    key: '401',
  });
  sessionClear();
};

// 將攔截器應用到自定義的axios實例
customAxios.interceptors.request.use(
    (config) => {
      const token = GetDecryptedString(localStorage.getItem('token'));
      if (token) {
        config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  

// 將response攔截器應用到自定義的axios實例
customAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.code === 'ERR_NETWORK') {
        // 檢查是否為檔案上傳請求
        const isFileUpload = error.config?.headers?.['Content-Type']?.includes('multipart/form-data');
        const isUploadEndpoint = error.config?.url?.includes('/upload_file');
        
        if (isFileUpload && isUploadEndpoint) {
          console.warn('檔案上傳失敗，可能是檔案過大');
          notification.warning({
            message: <div>檔案過大，請縮小檔案大小後重試</div>,
            placement: 'top',
            key: 'file-too-large',
            duration: 0,
            closeIcon: true,
          });
        } else {
          console.warn('網路連線錯誤');
          notification.warning({
            message: <div>網路連線錯誤，請檢查網路狀態後重試</div>,
            placement: 'top',
            key: 'network-error',
          });
        }
        return Promise.reject(error);
      }

      if (error.response) {
        let detail = error?.response?.data?.detail;
        switch (error.response.status) {
          case 400:
            if (detail?.includes('pageToken')) {
              console.warn('pageToken error');
            } else if (detail?.toLowerCase()?.includes('not authenticated')) {
              console.warn('Not Authenticated!');
              logout();
            } else {
              console.warn('Google Drive API error');
              notification.warning({
                message: <div>無效的請求或無權限</div>,
                placement: 'top',
                key: '400',
              });
            }
            break;
          case 401:
            console.warn('端點請求超時或無權限');
            logout();
            break;
          case 403:
            console.warn('沒有存取權限');
            notification.warning({
              message: <div>沒有存取權限，無權限使用此動作，如有疑問請通知系統管理員確認權限</div>,
              placement: 'top',
              key: '403',
            });
            break;
          case 404:
            if (detail?.includes('Not Authenticated!')) {
              console.warn('Not Authenticated!');
              logout();
            } else if (detail?.includes('File not found') && detail?.includes('drive/v3/files')) {
              console.warn('Google Drive 檔案權限錯誤');
              notification.warning({
                message: <div>無法存取 Google Drive 檔案，請確認資料夾權限，請向系統管理員確認</div>,
                placement: 'top',
                key: '404-drive',
                duration: 0,
              });
            } else {
              console.warn('找不到該頁面');
              notification.warning({
                message: <div>找不到該頁面</div>,
                placement: 'top',
                key: '404',
              });
            }
            break;
          case 413:
            console.warn('上傳檔案過大');
            notification.warning({
              message: <div>上傳檔案過大</div>,
              placement: 'top',
              key: '413',
            });
            break;
          
          case 500:
            const errorDetails = error.response.data.detail || '';
            const isPageTokenError = errorDetails?.includes('pageToken');
            if (isPageTokenError) {
              console.warn('pageToken error');
            } else {
              console.warn('伺服器出錯');
              notification.warning({
                message: <div>伺服器出錯</div>,
                placement: 'top',
                key: '500',
              });
            }
            break;
          case 503:
            console.warn('服務失效');
            notification.warning({
              message: <div>服務失效</div>,
              placement: 'top',
              key: '503',
            });
            break;
          case 504:
            console.warn('伺服器逾時');
            notification.warning({
              message: <div>伺服器逾時，請重新嘗試</div>,
              placement: 'top',
              key: '504',
            });
            break;
          default:
            console.warn(`連接錯誤: ${error.response.status}`);
        }
      }
      // 確保總是拒絕promise，傳遞錯誤以便後續處理
      return Promise.reject(error);
    }
  );

// 導出自定義的axios實例，而不是修改全局的axios
export default customAxios;
// 如果需要原始的axios，可以這樣導出
export { axios as originalAxios }; 